import React from "react"

import Button from "../../../UI/Button/button.js"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import shapeLeft from "../../../../assets/images/unsure-left.svg"
import shapeRight from "../../../../assets/images/unsure-right.svg"

const Unsure = ({ title, buttonText, to }) => {
  return (
    <section id="free-plan" className="section section--ttu-unsure">
      <img src={shapeLeft} alt="" className="bg-shape bg-shape--left" />
      <img src={shapeRight} alt="" className="bg-shape bg-shape--right" />
      <div className="wrapper">
        <p className="section--ttu-unsure__title">{title}</p>
        <Button to={to} brown={true} className="section--ttu-unsure__button">
          {buttonText}
        </Button>
      </div>
    </section>
  )
}

export default Unsure
