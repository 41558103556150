import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Plans from "../components/Sections/TalkToUs/Plans"
import Unsure from "../components/Sections/TalkToUs/Unsure"
import ogImage from "../assets/images/og_image.png"

const query = graphql`
  {
    wordpressPage(template: { eq: "page-talk-to-us.php" }) {
      acf {
        seo_title
        seo_description
        title
        subtitle
        free_call_title
        free_call_button_text
        free_call_direct_link
        plans_list {
          price
          title {
            part_1
          }
          description {
            part_1
            part_2
          }
          features {
            feature
          }
          direct_link
        }
      }
    }
  }
`

const AboutPage = () => {
  const data = useStaticQuery(query)

  const handleLink = link => {
    const ownerString = link.split("owner=")[1]
    const owner = ownerString.split("&")[0]
    const appId = link.split("appointmentType=")[1]
    return `/schedule-appointment?owner=${owner}&id=${appId}&free=1`
  }
  const { seo_title, seo_description } = data.wordpressPage.acf

  const { title, subtitle } = data.wordpressPage.acf
  const {
    free_call_title,
    free_call_button_text,
    free_call_direct_link,
  } = data.wordpressPage.acf
  const { plans_list } = data.wordpressPage.acf
  return (
    <Layout isDark="true">
      <SEO image={ogImage} title={seo_title} description={seo_description} />
      <Plans plans={plans_list} title={title} subtitle={subtitle} />
      <Unsure
        to={handleLink(free_call_direct_link)}
        title={free_call_title}
        buttonText={free_call_button_text}
      />
    </Layout>
  )
}

export default AboutPage
