import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import shapeLeft from "../../../../assets/images/plans-left.svg"
import shapeRight from "../../../../assets/images/plans-right.svg"
import plus from "../../../../assets/images/plus.svg"

const Plans = ({ title, subtitle, plans }) => {
  const handlePlanLink = link => {
    const ownerString = link.split("owner=")[1]
    const owner = ownerString.split("&")[0]
    const appId = link.split("id=")[1]
    return `/schedule-appointment?owner=${owner}&id=${appId}`
  }

  const renderPlan = (plan, i) => {
    return (
      <div className="plan" key={i}>
        <h3 className="plan__title">{plan.title.part_1}</h3>
        <p className="plan__price">
          {plan.price}
          <span className="currency">EUR</span>
        </p>
        <p className="plan__sessions">{plan.description.part_1}</p>
        <p className="plan__session-time">{plan.description.part_2}</p>
        <ul className="plan__features">
          {plan.features.map((featureSingle, x) => (
            <li key={x}>
              <img src={plus} className="plus" />
              <span>{featureSingle.feature}</span>
            </li>
          ))}
        </ul>
        <Link to={handlePlanLink(plan.direct_link)}>
          <button className="plan__button">Book a call</button>
        </Link>
      </div>
    )
  }

  return (
    <section className="section section--plans">
      <img src={shapeLeft} className="bg-shape bg-shape--left" />
      <img src={shapeRight} className="bg-shape bg-shape--right" />
      <div className="wrapper">
        <h1 className="section--plans__title">{title}</h1>
        <h2 className="section--plans__subtitle">{subtitle}</h2>
        <ul className="section--plans__plans">
          {plans.map((plan, i) => (
            <>{renderPlan(plan, i)}</>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Plans
